import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { Box, Breadcrumb, Divider, SEO, Text } from '../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="মাদ্রাসায় আপনার সাদাকাহ ও যাকাতে" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
  <Text variant="h2" textAlign="center" mdxType="Text">
    মাদ্রাসায় আপনার সাদাকাহ ও যাকাত
  </Text>
  <Divider mdxType="Divider" />
  <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/finance/',
        name: 'আর্থিক ব্যাপার'
      }]} mdxType="Breadcrumb" />
  <Divider mdxType="Divider" />
      <p>{`হিফয প্রতিষ্ঠানগুলোতে মূলত সামর্থ্যহীন দরিদ্র পরিবারের ছাত্রছাত্রীরে ভর্তি হয়ে থাকে। মাদ্রাসাতুল ইলম এর বাস্তাবতায় দেখা যায়, মূল খরচ বা বেতনের মাত্র ১০% আসে ছাত্রছাত্রীদের নিজস্ব অর্থ থেকে। বাকি ৯০% অর্থ ছাত্রছাত্রীরা মাদ্রাসাতুল ইলম এর সাদাকাহ ফান্ড থেকে সংগ্রহ করে বেতন পরিশোধ করে থাকে।`}</p>
      <p>{`সাদাকাহ ফান্ড টি পরিচালিত হয়ে আসছে আপনাদের মাসিক সাদাকাহ ও বাৎসরিক যাকাত সংগ্রহ করে। আমাদের কাছে সাদাকাহ বা যাকাত প্রেরণের ঠিকানা:`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Bank/Type`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Account Details`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`IBBL`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Name: Madrasatul Ilm `}<br />{` A/C No: 20506020200185414 `}<br />{` Branch: Agargaon/ IDB Bhaban `}<br />{` Routing No: 125262149 `}<br />{` Swift Code: IBBLBDDH224`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`IBBL`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Name: Khondakar Joynal Abedin/Md. Saiful Islam `}<br />{` A/C No: 20501910202485403 `}<br />{` Branch: Mohakhali Branch `}<br />{` Routing No: 125263193 `}<br />{` Swift Code: IBBLBDDH191`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`DBBL`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Name: Faisal Imtiaz`}<br />{` A/C No: 1051020009012 `}<br />{` Branch: Motijheel Foreign Exchange Branch `}<br />{` Routing No: 090274309 `}<br />{` Swift Code: DBBLBDDH105`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Rocket, Bkash, Nagad`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`01309 91 43 66`}</td>
          </tr>
        </tbody>
      </table>
      <p>{`আল্লাহ বলেন:`}</p>
      <p>{`“যারা আল্লাহর পথে তাদের সম্পদ ব্যয় করে, তাদের উপমা একটি বীজের মত, যা উৎপন্ন করল সাতটি শীষ, প্রতিটি শীষে রয়েছে একশ’ দানা। আর আল্লাহ যাকে চান তার জন্য বাড়িয়ে দেন। আর আল্লাহ প্রাচুর্যময়, সর্বজ্ঞ।” (সূরা আল-বাকারাহ ২: ২৬১)`}</p>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      